//Vercel Api
// export const Base_Url = "https://proposal-vercel-one.mkisan.com/api"

//Master Api
export const Base_Url = "https://api.master.proposal.ust.outbooks.com/api"

//Pre-Prod Api
// export const Base_Url = "https://api.preprod.proposal.ust.outbooks.com/api"

//Production Api
// export const Base_Url = "https://api.app.proposal.ust.outbooks.com/api"


//Vercel redirect
// export const redirectUri = "https://proposal-tool.vercel.app";

//Master redirect
export const redirectUri =  "https://master.proposal.outbooks.com";

//Pre-Prod redirect
// export const redirectUri =  "https://preprod.proposal.outbooks.com";

//Production redirect
// export const redirectUri =  "https://app.proposal.outbooks.com";
// export const mergePdfApiUrl = "http://localhost:3001/mergeUserPdfs";
// export const generatePdfUrl = "http://localhost:3001/generatePdf";
// export const saveImage = "http://localhost:3001/save-image";
export const mergePdfApiUrl = "https://api.pdfgenration.proposal.ust.outbooks.com/mergeUserPdfs";
export const generatePdfUrl = "https://api.pdfgenration.proposal.ust.outbooks.com/generatePdf";
export const saveImage = "https://api.pdfgenration.proposal.ust.outbooks.com/save-image";
export const CompaniesHouseBase_Url = "https://api.companieshouse.gov.uk"


export const OldOutbooksBaseUrl = "https://proposal.outbooks.com/api"
